/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { f, headingSecondary, ln } from 'src/components/common/Typography'
import { graphql, useStaticQuery } from 'gatsby'

import AboutSVG from 'src/assets/about.svg'
import { Container } from 'src/components/common/Container'
import { Squidex_About } from 'src/types/gatsby-types'
import { mq } from 'src/utils/mq'
import parse from 'html-react-parser'
import { theme } from 'src/utils/theme'

const wrapper = css`
  ${mq.md} {
    margin-top: 40px;
  }
`

const heading = css`
  ${headingSecondary}
  display: none;

  ${mq.md} {
    display: block;
    margin: 0 0 40px;
  }
`

const imageStyles = css`
  max-width: 80%;
  margin: 0 auto;
  display: block;

  ${mq.md} {
    max-width: 650px;
    margin-bottom: 48px;
  }
`

const textStyles = css`
  font-size: ${f(20)};
  line-height: ${ln(20, 34)};
  color: ${theme.colors.primaryText};
  padding: 0 16px;

  p {
    margin-bottom: 32px;
    margin-top: 0;
  }

  ${mq.md} {
    column-count: 2;
    column-gap: 40px;
  
    p {
      margin-bottom: 40px;
      margin-top: 0;
    }
  }
`

const titleStyles = css`
  ${heading}
  padding: 0 16px;
  color: ${theme.colors.primaryText}!important;
`

type Data = {
  squidex: {
    queryAboutContents: Squidex_About[]
  }
}

export const About: React.FC = () => {
  const response: Data = useStaticQuery(
    graphql`
      query {
        squidex {
          queryAboutContents {
            flatData {
              content
            }
          }
        }
      }    
    `,
  )

  const data = response?.squidex?.queryAboutContents?.[0]

  if (!data) {
    throw new Error('No data for About Page')
  }

  return (
    <Container css={wrapper}>
      <h2 css={titleStyles}>
        About RabbitPeepers
      </h2>
      <AboutSVG css={imageStyles} />
      <div css={textStyles}>
        {parse(data.flatData.content || '')}
      </div>
    </Container>
  )
}
