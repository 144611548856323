import { About } from 'src/components/About/About'
import { InterestedInServicesForm } from 'src/components/InterestedInServicesForm/InterestedInServicesForm'
import { Layout } from 'src/components/Layout/Layout'
import { PageProps } from 'gatsby'
import React from 'react'
import { TeamAll } from 'src/containers/TeamAll'

const AboutPage: React.FC<PageProps<{}>> = () => {
  return (
    <Layout title="About Us | RabbitPeepers">
      <About />
      <TeamAll hasDecoration={false} />
      <InterestedInServicesForm />
    </Layout>
  )
}

export default AboutPage
