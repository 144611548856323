/** @jsx jsx */

import { graphql, useStaticQuery } from 'gatsby'

import { Squidex_TeamMember } from 'src/types/gatsby-types'
import { Team } from 'src/components/Team/Team'
import { jsx } from '@emotion/react'

type Data = {
  squidex: {
    queryTeamMemberContents: Squidex_TeamMember[]
  }
}

type Props = {
  hasDecoration?: boolean
}

export const TeamAll: React.FC<Props> = ({ hasDecoration = true }) => {
  const response: Data = useStaticQuery(
    graphql`
      {
        squidex {
          queryTeamMemberContents {
            flatData {
              description
              name
              photo {
                ...TeamMemberImage
              }
              position
              showInAllTeam
            }
            id
          }
        }
      }
    `,
  )

  const data = response?.squidex?.queryTeamMemberContents || []

  if (!data?.length) {
    return null
  }

  return (
    <Team
      team={data.filter((member) => member.flatData.showInAllTeam)}
      hasDecoration={hasDecoration}
    />
  )
}
